const columns = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
  },
  {
    field: 'type',
    headerName: 'Type',
    type: 'reminder',
  },
  {
    field: ['durationNumber', 'durationType'],
    headerName: 'Before',
    type: 'duration',
  },
]

export default columns
