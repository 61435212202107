import React, { memo, useEffect, useState, useRef } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import CustomButton from 'components/CustomButtons/Button'
import Form from 'components/Form'
import TemplateModel from 'models/Template'
import defaults from './defaults'
import Editor from './editor'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { types } from 'constants/reminders'
import { FormHelperText } from '@material-ui/core'

export default memo(
  ({
    buttonText,
    discardButtonText,
    title,
    subTitle,
    onSubmit,
    onDiscard,
    defaultValues = defaults,
    dirtyOnStart = true,
  }) => {
    const hookForm = useForm({
      resolver: yupResolver(TemplateModel.validationSchema),
      defaultValues: { ...defaultValues },
    })
    const {
      control,
      formState: { errors, isSubmitSuccessful },
      watch,
    } = hookForm
    const editorRef = useRef()

    const watchType = watch('type')

    useEffect(() => {
      if (isSubmitSuccessful) {
        window.onbeforeunload = null
      }
    }, [isSubmitSuccessful])

    useEffect(() => {
      hookForm.reset({ ...defaultValues })
    }, [defaultValues])

    const onOpenEditor = () => {
      editorRef.current.open()
    }

    return (
      <Form
        title={title}
        subTitle={subTitle}
        dirtyOnStart={dirtyOnStart}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        defaultValues={defaultValues}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <CustomInput {...field} label="Name" error={errors.name} fullWidth />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <CustomSelect {...field} label="Type" options={types} error={errors.type} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        {watchType && (
          <>
            {watchType === 'email' && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Controller
                    control={control}
                    name="subject"
                    render={({ field }) => <CustomInput {...field} label="Subject" error={errors.subject} fullWidth />}
                  />
                </GridItem>
              </GridContainer>
            )}
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                <CustomButton color="primary" onClick={onOpenEditor}>
                  Open Editor
                </CustomButton>
                {errors.template && <FormHelperText error>{errors.template.message}</FormHelperText>}
              </GridItem>
            </GridContainer>
          </>
        )}
        <Controller
          control={control}
          name="template"
          render={({ field }) => (
            <Editor {...field} label="Name" ref={editorRef} error={errors.name} type={watchType} fullWidth />
          )}
        />
      </Form>
    )
  }
)
