import React, { forwardRef } from 'react'
import { ImageList, ImageListItem, FormLabel, FormHelperText, FormControl } from '@material-ui/core'
import CustomCheckbox from 'components/CustomCheckbox'
import useStyles from './styles'

export default forwardRef(({ label, lists = [], cols = 2, value, error, onChange }, ref) => {
  const classes = useStyles()

  const onChangeCheckList = (list, checked) => {
    let currentValue = Object.assign([], value)
    if (checked) {
      if (Array.isArray(list.value)) {
        currentValue = currentValue.concat(list.value)
      } else {
        currentValue.push(list.value)
      }
    } else {
      const checkedIndex = currentValue.findIndex((v) => v === list.value)
      currentValue.splice(checkedIndex, 1)
    }
    onChange(currentValue)
  }

  return (
    <FormControl className={classes.root} error={!!error} ref={ref}>
      <FormLabel className={classes.label} error={!!error}>
        {label}
      </FormLabel>
      <ImageList rowHeight={40} cols={cols} error={error}>
        {lists.map((list, key) => (
          <ImageListItem key={key}>
            <CustomCheckbox
              InputLabelProps={{ label: list.label }}
              value={
                Array.isArray(list.value)
                  ? list.value.filter((v) => value.includes(v)).length === list.value.length
                  : !!value.find((v) => list.value === v)
              }
              onChange={(checked) => onChangeCheckList(list, checked)}
            />
          </ImageListItem>
        ))}
      </ImageList>
      {error && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
})
