const columns = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
  },
  {
    field: 'permissions',
    headerName: 'Permissions',
    type: 'permission',
  },
]

export default columns
