import Payment from './index'
import timestamp from 'utils/firebaseTimestamp'

const TransactionConverter = {
  toFirestore: (group) => {
    const { id, name, description, ...fs } = group

    return {
      ...fs,
      ...timestamp(id),
    }
  },
  fromFirestore: (snapshot, options) => {
    const payment = snapshot.data(options)
    return new Payment(payment.ref, payment.status, payment.transactionId, payment.confirmedAt?.toDate(), snapshot.id)
  },
}

export default TransactionConverter
