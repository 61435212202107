import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Hidden from '@material-ui/core/Hidden'
// @material-ui/icons
import Menu from '@material-ui/icons/Menu'
// core components
import AdminNavbarLinks from './AdminNavbarLinks.js'
import Breadcrumbs from './Breadcrumbs'

import styles from 'assets/jss/material-dashboard-react/components/headerStyle.js'

const useStyles = makeStyles(styles)

const Navbar = ({ handleDrawerToggle, color, ...props }) => {
  const classes = useStyles()

  const appBarClasses = classNames({
    [' ' + classes[color]]: color,
  })

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Breadcrumbs {...props} />
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
