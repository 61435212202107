import React, { forwardRef } from 'react'
import useStyles from './styles'
import { Typography } from '@material-ui/core'

export default forwardRef(({ label, ...props }, ref) => {
  const classes = useStyles()

  return (
    <>
      <Typography component="legend">{label}</Typography>
      <input className={classes.input} type="color" ref={ref} {...props} />
    </>
  )
})
