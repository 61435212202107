import React, { useEffect, memo } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import { symbol } from 'utils/currency'
import Form from 'components/Form'
import FeeModel from 'models/Fee'
import defaults from './defaults'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { InputAdornment } from '@material-ui/core'

export default memo(
  ({
    buttonText,
    discardButtonText,
    title,
    subTitle,
    onSubmit,
    onDiscard,
    defaultValues = defaults,
    dirtyOnStart = true,
  }) => {
    const hookForm = useForm({ resolver: yupResolver(FeeModel.validationSchema), defaultValues: { ...defaultValues } })
    const {
      control,
      formState: { errors },
      reset,
    } = hookForm

    useEffect(() => {
      reset({ ...defaultValues })
    }, [defaultValues])

    return (
      <Form
        title={title}
        subTitle={subTitle}
        dirtyOnStart={dirtyOnStart}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <CustomInput {...field} label="Name" error={errors.name} fullWidth />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <CustomInput {...field} label="Description" error={errors.description} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="amount"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  type="number"
                  label="Amount"
                  error={errors.amount}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{symbol}</InputAdornment>,
                  }}
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
      </Form>
    )
  }
)
