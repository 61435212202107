import React, { memo } from 'react'
import { navigate } from 'gatsby'
import { useAuth } from 'hooks/useAuth'
import EmptyAdmin from 'components/Admin/Empty'

const PrivateRoute = memo(({ component: Component, permission, location, ...rest }) => {
  const [loading, auth, idToken] = useAuth()

  if (loading) {
    return <EmptyAdmin location={location} {...rest} />
  }

  if (!auth) {
    navigate(`/login?ret=${location.pathname}`)
    return null
  } else if (permission && (!idToken || !idToken.claims.permissions.includes(permission))) {
    navigate('/admin')
    return null
  }

  return <Component location={location} {...rest} />
})

export default PrivateRoute
