import React, { forwardRef, useRef, memo, useImperativeHandle, useState } from 'react'
import { Dialog, Slide, AppBar, Toolbar, Typography } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import EmailEditor from 'components/EmailEditor'
import SmsEditor from 'components/SmsEditor'
import useStyles from './dialogStyles'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const EditorToolbar = ({ classes, onClose, onSave }) => (
  <AppBar className={classes.appBar}>
    <Toolbar>
      <Typography variant="h6" className={classes.title}>
        Template Editor
      </Typography>
      <Button onClick={onClose}>Close</Button>
      <Button color="primary" onClick={onSave}>
        Save
      </Button>
    </Toolbar>
  </AppBar>
)

const Editor = memo(
  forwardRef(({ type, value, ...props }, ref) => {
    switch (type) {
      case 'sms':
        return (
          <div style={{ padding: '10px 20px' }}>
            <SmsEditor ref={ref} label="Message" fullWidth value={value.template} {...props} />
          </div>
        )
      case 'email':
        return <EmailEditor ref={ref} {...value} {...props} />
      default:
        return <></>
    }
  })
)

export default memo(
  forwardRef(({ value, onChange, type = 'sms' }, ref) => {
    const classes = useStyles()
    const editorRef = useRef(null)
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpen(true)
      },
      close: () => onClose(),
    }))

    const onClose = () => setOpen(false)
    const onSave = async () => {
      const { template, html } = await editorRef.current.getData()
      onChange({ template, html })
      onClose()
    }

    return (
      <Dialog fullScreen disableEscapeKeyDown={true} onClose={onClose} TransitionComponent={Transition} open={open}>
        <EditorToolbar classes={classes} onSave={onSave} onClose={onClose} />
        <Editor type={type} ref={editorRef} value={value} />
      </Dialog>
    )
  })
)
