import React, {useState} from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardHeader from 'components/Card/CardHeader'
import CardBody from 'components/Card/CardBody'
import Datatable from './Datatable'
import useStyles from './styles'
import useFetchGridData from 'hooks/useFetchGridData'

const DataGrid = ({ title, fetchData, reload, ...props }) => {
  const classes = useStyles()
  const {
    data,
    lastItem,
    pageInfo: { page, limit },
    setPageInfo,
    setKeyword,
  } = useFetchGridData(fetchData, reload)

  const onPageChange = (e, p) => {
    setPageInfo({ page: p, action: page > p ? -1 : 1, limit })
  }

  const onRowsPerPageChange = ({ target: { value } }) => {
    setPageInfo({ page, limit: value })
  }

  const onSearch = (keyword) => setKeyword(keyword)

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>{title}</h4>
          </CardHeader>
          <CardBody style={{ minHeight: 800, width: '97%' }}>
            <Datatable
              rows={data}
              page={page}
              limit={limit}
              lastItem={lastItem}
              {...props}
              onPageChange={onPageChange}
              onSearch={onSearch}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
export default DataGrid
