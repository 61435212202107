import React, { lazy, memo, Suspense } from 'react'
import Empty from 'components/Admin/Empty'
import { updatePaymentGateway } from 'actions/paymentGateway'

const Gateway = memo(({ gateway, conf, ...props }) => {
  const Gateway = lazy(() => import(`../gateways/${gateway}/edit`))

  const onSubmit = async (data) => updatePaymentGateway(gateway, data)

  return (
    <Suspense fallback={<Empty {...props} />}>
      <Gateway config={conf} onSubmit={onSubmit} />
    </Suspense>
  )
})

export default Gateway
