import React, { useState, useEffect, forwardRef } from 'react'
import { List } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import CustomInput from 'components/CustomInput'
import useStyles from './styles'
import ListItem from './ListItem'

export default forwardRef(({ options, label, value, error, onChange, primary = 'name', secondary, ...props }, ref) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [displayOptions, setDisplayOptions] = useState([])

  useEffect(() => {
    setDisplayOptions(options.map((o) => o.id))
  }, [options])
  // const [options, setOptions] = useState([])

  // useEffect(() => {
  //   fetchItems({ limit: 100 }).then(({ data }) => {
  //     setOptions(data)
  //     setDisplayOptions(data.map(d => d.id))
  //   })
  // }, [])

  const onArrange = (item, upOrDown) => {
    const index = value.indexOf(item.id)
    let newValue = [...value]
    newValue.splice(index, 1)
    newValue.splice(index + upOrDown, 0, item.id)
    onChange(newValue)
  }

  const getSelectedOptions = (selected) => selected.map((s) => options.find((o) => o.id === s))

  return (
    <>
      <Autocomplete
        open={open}
        onOpen={() => {
          setOpen(true)
        }}
        onClose={() => {
          setOpen(false)
        }}
        ref={ref}
        multiple
        getOptionSelected={(option, value) => option === value}
        getOptionLabel={(option) => options.find((o) => o.id === option)?.name}
        options={displayOptions}
        renderInput={(params) => <CustomInput {...params} error={error} label={label} />}
        onChange={(e, value) => onChange(value)}
        value={value}
        {...props}
      />
      {value && (
        <List className={classes.root} style={{ marginTop: '1rem' }}>
          {getSelectedOptions(value)
            .filter((v) => v)
            .map((item, index) => (
              <ListItem
                key={item.id}
                item={item}
                primary={primary}
                secondary={secondary}
                start={index === 0}
                end={index === value.length - 1}
                onArrange={onArrange}
              />
            ))}
        </List>
      )}
    </>
  )
})
