import React, { memo } from 'react'
import { emphasize, withStyles } from '@material-ui/core/styles'
import { NavigateNext } from '@material-ui/icons'
import { Breadcrumbs, Chip } from '@material-ui/core'
import { Link } from 'gatsby'
import { SLOT_LABEL, FEE_LABEL, GROUP_LABEL } from 'config/app'

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'capitalize',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip)

const replaceLabel = (s) =>
  s === 'groups' ? `${GROUP_LABEL}s` : s === 'slots' ? `${SLOT_LABEL}s` : s === 'fees' ? `${FEE_LABEL}s` : s

export default memo(({ root = 'admin', location }) => {
  const breadCrumbs = location.pathname.split('/').filter((u) => u && u !== 'edit')

  const to = (link, key) => {
    return key ? breadCrumbs.slice(0, key + 1).join('/') : link
  }

  return (
    <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
      {breadCrumbs.map((b, k) => (
        <StyledBreadcrumb
          key={k}
          component={k === breadCrumbs.length - 1 ? undefined : Link}
          clickable={k !== breadCrumbs.length - 1}
          to={`/${to(b, k)}`}
          label={b === root ? 'home' : replaceLabel(b)}
        />
      ))}
    </Breadcrumbs>
  )
})
