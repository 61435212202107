import Fee from './index'
import timestamp from 'utils/firebaseTimestamp'

const FeeConverter = {
  toFirestore: (fee) => {
    const { id, name, description, ...fs } = fee
    return {
      ...fs,
      search: `${name.toLowerCase()} ${description?.toLowerCase()}`,
      name,
      description,
      ...timestamp(id),
    }
  },
  fromFirestore: (snapshot, options) => {
    const fee = snapshot.data(options)
    return new Fee(fee.name, fee.description, fee.amount, fee.slots, fee.deletedAt, snapshot.id)
  },
}

export default FeeConverter
