import React, { memo } from 'react'
import Form from './Form'
import { getSettings, updateSettings } from 'actions/setting'
import { getAllTemplates } from 'actions/template'
import useApi from 'hooks/useApi'

const SettingPage = memo(() => {
  const [settings, templates] = useApi(() => Promise.all([getSettings(), getAllTemplates()]))

  const onSubmit = (data) => updateSettings(settings.set(data))

  return <Form buttonText="Save" settings={settings} onSubmit={onSubmit} templates={templates?.data} />
})

export default SettingPage
