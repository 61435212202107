import React, { useState, memo } from 'react'
import DataGrid from 'components/DataGrid'
import { getAllReminders, deleteReminder } from 'actions/reminder'
import columns from './columns'
import { navigate } from 'gatsby'

const ReminderListing = memo(() => {
  const [reload, setReload] = useState(false)

  const onAdd = () => {
    navigate('/admin/reminders/new')
  }

  const onEdit = (id) => {
    navigate(`/admin/reminders/edit/${id}`)
  }

  const onDelete = (reminder) => {
    deleteReminder(reminder.id).then(() => {
      setReload(true)
    })
  }

  return (
    <DataGrid
      columns={columns}
      fetchData={getAllReminders}
      onDelete={onDelete}
      onAdd={onAdd}
      onEdit={onEdit}
      title="Reminders"
      reload={reload}
    />
  )
})

export default ReminderListing
export { default as EditReminder } from './Edit'
export { default as NewReminder } from './New'
