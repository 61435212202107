import React, { memo, lazy, Suspense } from 'react'
import Empty from 'components/Admin/Empty'
import { deletePaymentGateway, unDeletePaymentGateway } from 'actions/paymentGateway'
import { useLoading } from 'hooks/useLoading'
import { Card, CardActions, CardMedia, CardContent } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import { navigate } from 'gatsby'

const Gateway = memo(({ gateway, config, classes, ...props }) => {
  const Logo = lazy(() => import(`./gateways/${gateway}/logo`))
  const [, setLoading] = useLoading()

  const onDisable = async () => {
    setLoading(true)
    if (config.deletedAt) {
      unDeletePaymentGateway(gateway, config).then(() => setLoading(false))
    } else {
      deletePaymentGateway(gateway).then(() => window.location.reload())
    }
  }
  const onEdit = () => navigate(`/admin/payments/edit/${gateway}`)

  return (
    <Suspense fallback={<Empty {...props} />}>
      <Card>
        <CardContent>
          <CardMedia component={Logo} />
        </CardContent>
        <CardActions>
          <Button onClick={onEdit} fullWidth>
            {config ? 'Edit' : 'Setup'}
          </Button>
        </CardActions>
        {config && (
          <CardActions>
            <Button
              className={classes.DisableButton}
              variant="text"
              color={config.deletedAt ? 'primary' : 'secondary'}
              size="small"
              onClick={onDisable}
              fullWidth
            >
              {config.deletedAt ? 'Enable' : 'Disable'}
            </Button>
          </CardActions>
        )}
      </Card>
      {/* <Gateway config={config} {...props} onDisable={onDisable} /> */}
    </Suspense>
  )
})

export default Gateway
