import yup from 'utils/yup'

export default yup.object({
  currentPassword: yup
    .string()
    .required('Current password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  newPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  confirmPassword: yup
    .string()
    .required('Password is required')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .oneOf([yup.ref('newPassword'), null], 'Passwords must be matched'),
})
