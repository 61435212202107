import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  text: {
    marginTop: 15,
  },
  removeButton: {
    marginTop: -12,
  },
}))
