import React from 'react'
import Form from '../Form'
import useApi from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { getUser, updateUser } from 'actions/user'
import { getAllRoles } from 'actions/role'
import { getAllSlots } from 'actions/slot'
import { assignSlotPermission } from 'constants/permissions'

const AdminUserEdit = ({ userId }) => {
  const [user, roles, slots] = useApi(() => Promise.all([getUser(userId), getAllRoles(), getAllSlots()]))
  const [, , idToken] = useAuth()

  const onSubmit = (data) => updateUser(userId, user.set(data))

  return (
    <Form
      buttonText="Update"
      onSubmit={onSubmit}
      title="Edit User"
      defaultValues={user}
      dirtyOnStart={false}
      roles={roles?.data}
      slots={slots?.data}
      assignSlot={idToken?.claims?.permissions.includes(assignSlotPermission)}
    />
  )
}
export default AdminUserEdit
