const columns = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'type',
    headerName: 'Type',
    type: 'reminder',
  },
]
export default columns
