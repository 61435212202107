import React, { memo } from 'react'
import { getPaymentGateway } from 'actions/paymentGateway'
import useApi from 'hooks/useApi'

import Gateway from './gateway'

const PaymentEdit = memo(({ gateway, ...props }) => {
  const [paymentGateway] = useApi(() => Promise.all([getPaymentGateway(gateway)]))

  return <Gateway {...props} gateway={gateway} conf={paymentGateway?.conf} />
})

export default PaymentEdit
