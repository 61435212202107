import React from 'react'
import { getAllSlotTimetables } from 'actions/slotTimetable'
import { getSettings } from 'actions/setting'
import Calendar from 'components/Calendar'
import useApi from 'hooks/useApi'
import Logo from 'components/Logo'
import { OPEN_TIME, CLOSE_TIME, CALENDAR_TIME_STEP } from 'config/app'

const Schedules = ({ isPrivate = true, showBooking = false, onSelectTimetable, minDate, customButtons }) => {
  const settings = useApi(() => getSettings())

  const loadData = async ({ day, isPrivate }) => getAllSlotTimetables({ day, isPrivate })

  const eventPropGetter = (timetable) => ({ style: timetable.getColor(settings.colors) })

  const titleAccessor = (timetable) => {
    return timetable.getTitle(showBooking)
  }

  return (
    <Calendar
      isPrivate={isPrivate}
      loadData={loadData}
      startTime={OPEN_TIME}
      endTime={CLOSE_TIME}
      onSelectEvent={onSelectTimetable}
      eventPropGetter={eventPropGetter}
      titleAccessor={titleAccessor}
      minDate={minDate}
      step={CALENDAR_TIME_STEP}
      logo={<Logo />}
      customButtons={customButtons}
    />
  )
}

export default Schedules
