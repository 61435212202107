import React, { useState, memo } from 'react'
import { navigate } from 'gatsby'
import DataGrid from 'components/DataGrid'
import columns from './columns'
import { getAllGroups, deleteGroup } from 'actions/group'
import { GROUP_LABEL } from 'config/app'

const GroupListing = memo(() => {
  const [reload, setReload] = useState(false)

  const onAdd = async () => navigate('/admin/groups/new')

  const onEdit = (id) => navigate(`/admin/groups/edit/${id}`)

  const onDelete = (group) => {
    deleteGroup(group.id).then(() => {
      setReload(true)
    })
  }

  return (
    <DataGrid
      columns={columns}
      fetchData={getAllGroups}
      onDelete={onDelete}
      onAdd={onAdd}
      onEdit={onEdit}
      title={`${GROUP_LABEL}s`}
      reload={reload}
    />
  )
})

export default GroupListing
export { default as EditGroup } from './Edit'
export { default as NewGroup } from './New'
