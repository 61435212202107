import React from 'react'
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar, Legend, Tooltip, CartesianGrid } from 'recharts'

const RechartBar = ({ data, keys, colors }) => (
  <ResponsiveContainer width="100%" height="80%">
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="weekday" />
      <YAxis />
      <Legend verticalAlign="bottom" align="right" />
      <Tooltip />
      {keys.map((key) => (
        <Bar name={key.label} key={key.value} dataKey={key.label} fill={colors[key.value]} />
      ))}
    </BarChart>
  </ResponsiveContainer>
)

export default RechartBar
