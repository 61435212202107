import React, { useState, useEffect } from 'react'
import { GridContainer, GridItem } from 'components/Grid'
import { Controller } from 'react-hook-form'
import CustomCheckbox from 'components/CustomCheckbox'
import CustomSelect from 'components/CustomSelect'

const Notifications = ({ classes, hookForm, templates }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = hookForm
  const [bookingSmsEnabled, bookingEmailEnabled] = watch(['sms.enabled', 'email.enabled'])
  const [{ smsTemplates, emailTemplates }, setTemplates] = useState({})

  useEffect(() => {
    if (templates.length > 0) {
      const smsTemplates = []
      const emailTemplates = []
      templates.forEach((t) => {
        switch (t.type) {
          case 'sms':
            smsTemplates.push(t)
            break
          default:
            emailTemplates.push(t)
            break
        }
      })
      setTemplates({ smsTemplates, emailTemplates })
    }
  }, [templates])

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Controller
            control={control}
            name="sms.enabled"
            render={({ field }) => (
              <CustomCheckbox
                {...field}
                InputLabelProps={{
                  label: 'Send SMS Confirmation',
                  labelPlacement: 'start',
                  className: classes.checkbox,
                }}
              />
            )}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Controller
            control={control}
            name="sms.templateId"
            render={({ field }) => (
              <CustomSelect
                {...field}
                label="Choose Template"
                options={smsTemplates}
                optionLabel="name"
                optionValue="id"
                disabled={!bookingSmsEnabled}
                error={errors.sms?.templateId}
                fullWidth
              />
            )}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Controller
            control={control}
            name="email.enabled"
            render={({ field }) => (
              <CustomCheckbox
                {...field}
                InputLabelProps={{
                  label: 'Send Email Confirmation',
                  labelPlacement: 'start',
                  className: classes.checkbox,
                }}
              />
            )}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <Controller
            control={control}
            name="email.templateId"
            render={({ field }) => (
              <CustomSelect
                {...field}
                label="Choose Template"
                error={errors.email?.templateId}
                options={emailTemplates}
                optionLabel="name"
                optionValue="id"
                disabled={!bookingEmailEnabled}
                fullWidth
              />
            )}
          />
        </GridItem>
      </GridContainer>
    </>
  )
}
export default Notifications
