import Time from 'models/Time'

const getObject = (time) =>
  new Time(
    time.type,
    time.isPrivate,
    time.name,
    time.description,
    time.startDate,
    time.endDate,
    time.startTime,
    time.endTime,
    time.interval,
    time.days,
    time.slots
  )

export default getObject
