import React, { useEffect, useRef } from 'react'
import Form from '../Form'
import { navigate } from 'gatsby'
import { createTime } from 'actions/time'
import { useLoading } from 'hooks/useLoading'
import ConfirmDialog from 'components/ConfirmDialog'

const NewTime = () => {
  const [, setLoading] = useLoading()
  const newDialogRef = useRef(null)

  useEffect(() => {
    setLoading(false)
  }, [])

  const onSubmit = (data) =>
    createTime(data).then((doc) => {
      newDialogRef.current.open(doc)
    })

  const onDiscard = () => {
    navigate('/admin/times')
  }

  return (
    <>
      <Form
        buttonText="Create"
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        discardButtonText="Close"
        title="Create Time"
      />
      <ConfirmDialog
        title="New or Edit"
        confirmButtonText="Create another record"
        text="Do you want to create another record?"
        ref={newDialogRef}
        onConfirm={() => navigate(`/admin/times/new`)}
        onClose={(doc) => navigate(`/admin/times/edit/${doc.id}`)}
      />
    </>
  )
}

export default NewTime
