import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class TimeModel extends BaseModel {
  constructor(
    type = 0,
    isPrivate = false,
    name = '',
    description = '',
    startDate = null,
    endDate = null,
    startTime = null,
    endTime = null,
    interval = 5,
    days = [],
    slots = [],
    deletedAt = null,
    id = null
  ) {
    super()
    this.type = type
    this.isPrivate = isPrivate
    this.name = name
    this.description = description
    this.startDate = startDate
    this.endDate = endDate
    this.startTime = startTime
    this.endTime = endTime
    this.interval = parseInt(interval)
    this.days = days
    this.deletedAt = deletedAt
    this.slots = slots
    this.id = id
  }

  static validationSchema = yup.object({
    type: yup.string().label('Type').required(),
    name: yup.string().label('Name').required(),
    startDate: yup
      .date()
      .label('Start Date')
      .required()
      .transform((v, o) => (o === '' ? undefined : v)),
    endDate: yup
      .date()
      .label('End Date')
      .required()
      .transform((v, o) => (o === '' ? undefined : v)),
    startTime: yup
      .date()
      .label('Start Time')
      .required()
      .transform((v, o) => (o === '' ? undefined : v)),
    endTime: yup
      .date()
      .label('End Time')
      .required()
      .transform((v, o) => (o === '' ? undefined : v)),
    interval: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .min(1)
      .label('Interval')
      .required(),
    days: yup.array().label('Days').required(),
  })
}
export default TimeModel
