const addPersonalizationTags = (rte, tags) => {
  rte.add('tags', {
    icon: `<select><option></option>${tags.map(
      ({ label, value }) => `<option value="${value}">${label}</option>`
    )}</select>`,
    result: (rte, action) => rte.insertHTML(action.btn.firstChild.value),
  })
}

export const initRte = (rte, tags) => {
  addPersonalizationTags(rte, tags)
}
