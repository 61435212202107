import React, { memo, useEffect, useState } from 'react'
import CustomInput from 'components/CustomInput'
import Form from 'components/Form'
import { useForm, Controller } from 'react-hook-form'
import defaultValues from './defaults'
import { useLoading } from 'hooks/useLoading'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { getAllPayments } from 'actions/payment'
import Transaction from './transaction'
import { yupResolver } from '@hookform/resolvers/yup'
import validations from './validations'

const TransactionsListing = memo((props) => {
  const [, setLoading] = useLoading()
  const [trans, setTrans] = useState([])

  const hookForm = useForm({
    defaultValues,
    resolver: yupResolver(validations),
  })
  const { control } = hookForm

  const onSubmit = async ({ ref }) => {
    const where = [['transactionId', '==', ref]]
    const payments = await getAllPayments({ where })
    setTrans(payments?.data)
  }

  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <>
      <Form
        title="Transactions"
        subTitle="Search Transactions"
        hookForm={hookForm}
        onSubmit={onSubmit}
        submitButtonText="Search"
        discardButton={false}
        confirm={false}
        dirtyOnStart
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="ref"
              render={({ field, fieldState: { error } }) => (
                <CustomInput {...field} label="Transaction Ref" error={error} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
      </Form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <GridContainer>
            {trans?.map((transaction) => (
              <GridItem key={transaction.id} xs={4} sm={3} md={2}>
                <Transaction transaction={transaction} />
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  )
})

export default TransactionsListing
