import React, { memo } from 'react'
import { ListItem, ListItemSecondaryAction, ListItemText, Divider, IconButton } from '@material-ui/core'
import {
  ArrowUpwardTwoTone as ArrowUpwardTwoToneIcon,
  ArrowDownwardTwoTone as ArrowDownwardTwoToneIcon,
} from '@material-ui/icons'

export default memo(({ start, end, item, primary, secondary, onArrange }) => (
  <>
    <ListItem dense button>
      <ListItemText primary={item[primary]} />
      {secondary && <ListItemText secondary={typeof secondary === 'function' ? secondary(item) : item[secondary]} />}
      <ListItemSecondaryAction>
        {!start && (
          <IconButton edge="end" aria-label="up" onClick={() => onArrange(item, -1)}>
            <ArrowUpwardTwoToneIcon />
          </IconButton>
        )}
        {!end && (
          <IconButton edge="end" aria-label="down" onClick={() => onArrange(item, 1)}>
            <ArrowDownwardTwoToneIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
    <Divider />
  </>
))
