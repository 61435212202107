import Reminder from 'models/Reminder'

const getObject = (reminder) =>
  new Reminder(
    reminder.name,
    reminder.description,
    reminder.type,
    reminder.bookingStatus,
    reminder.durationNumber,
    reminder.durationType,
    reminder.templateId
  )

export default getObject
