import Time from './index'
import timestamp from 'utils/firebaseTimestamp'
import { set, getHours, getMinutes } from 'date-fns'

const TimeConverter = {
  toFirestore: (time) => {
    const { id, name, description, startDate, startTime, endTime, endDate, ...fs } = time
    return {
      ...fs,
      search: `${name.toLowerCase()} ${description?.toLowerCase()}`,
      name,
      description,
      startDate,
      startTime,
      endTime,
      endDate,
      start: set(startDate, {
        hours: getHours(startTime),
        minutes: getMinutes(startTime),
        seconds: 0,
        milliseconds: 0,
      }),
      end: set(endDate, {
        hours: getHours(endTime),
        minutes: getMinutes(endTime),
        seconds: 0,
        milliseconds: 0,
      }),
      ...timestamp(id),
    }
  },
  fromFirestore: (snapshot, options) => {
    const time = snapshot.data(options)
    return new Time(
      time.type,
      time.isPrivate,
      time.name,
      time.description,
      time.startDate.toDate(),
      time.endDate.toDate(),
      time.startTime.toDate(),
      time.endTime.toDate(),
      time.interval,
      time.days,
      time.slots,
      time.deletedAt,
      snapshot.id
    )
  },
}
export default TimeConverter
