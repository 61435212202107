const columns = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    type: 'date',
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    type: 'date',
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    type: 'time',
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    type: 'time',
  },
  {
    field: 'interval',
    headerName: 'Interval',
  },
  {
    field: 'days',
    headerName: 'Days',
  },
]
export default columns
