import React, { memo } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Logo from 'components/Logo/small'

const Brand = memo(({ classes }) => (
  <div className={classes.logo}>
    <Link to="/admin" className={classNames(classes.logoLink)}>
      <div className={classes.logoImage}>
        <Logo />
      </div>
      Getslot
    </Link>
  </div>
))

export default Brand
