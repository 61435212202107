import React from 'react'
import Form from '../Form'
import { getTime, updateTime } from 'actions/time'
import useApi from 'hooks/useApi'

const EditTimes = ({ timeId }) => {
  const [time] = useApi(() => Promise.all([getTime(timeId)]))

  const onSubmit = (data) => updateTime(timeId, time.set(data))

  return <Form buttonText="Update" onSubmit={onSubmit} title="Edit Time" defaultValues={time} dirtyOnStart={false} />
}

export default EditTimes
