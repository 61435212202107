import React from 'react'
import { useLoading } from 'hooks/useLoading'
import { getFile } from 'actions/dashboard'
import { getSettings } from 'actions/setting'
import useApi from 'hooks/useApi'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import WeeklyStatus from './weeklyStatus'

const AdminDashboard = () => {
  const [loading] = useLoading()

  const [data, settings] = useApi(() => Promise.all([getFile(), getSettings()]))

  return (
    <>
      {!loading && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <WeeklyStatus settings={settings} data={data} />
          </GridItem>
        </GridContainer>
      )}
    </>
  )
}

export default AdminDashboard
