import React, { useEffect } from 'react'
import Form from '../Form'
import { navigate } from 'gatsby'
import { createFee } from 'actions/fee'
import { useLoading } from 'hooks/useLoading'
import { FEE_LABEL } from 'config/app'

const NewFee = () => {
  const [, setLoading] = useLoading()

  useEffect(() => {
    setLoading(false)
  }, [])

  const onSubmit = (data, reset) => {
    createFee(data).then((doc) => {
      navigate(`/admin/fees/edit/${doc.id}`)
    })
  }

  const onDiscard = () => {
    navigate('/admin/fees')
  }

  return (
    <Form
      buttonText="Create"
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      discardButtonText="Close"
      title={`Create ${FEE_LABEL}`}
    />
  )
}
export default NewFee
