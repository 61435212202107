import React from 'react'
import Form from '../Form'
import { navigate } from 'gatsby'
import { createGroup, newGroupDoc } from 'actions/group'
import { getAllTimes } from 'actions/time'
import { getAllFees } from 'actions/fee'
import useApi from 'hooks/useApi'
import { SLOT_LABEL, FEE_LABEL, GROUP_LABEL } from 'config/app'

const NewGroup = () => {
  const [times, fees] = useApi(() => Promise.all([getAllTimes(), getAllFees()]))

  const onSubmit = async (data) => {
    const doc = await newGroupDoc()
    return createGroup(data, doc.id).then(() => navigate(`/admin/groups/edit/${doc.id}`))
  }

  const onDiscard = () => {
    navigate('/admin/groups')
  }

  return (
    <Form
      buttonText="Create"
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      discardButtonText="Close"
      title={`Create ${GROUP_LABEL}`}
      times={times?.data}
      fees={fees?.data}
    />
  )
}

export default NewGroup
