export default {
  name: '',
  type: '',
  isPrivate: false,
  description: '',
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
  interval: 10,
  days: [],
}
