import React from 'react'
import Form from '../Form'
import { navigate } from 'gatsby'
import { createUser } from 'actions/user'
import { getAllRoles } from 'actions/role'
import { getAllSlots } from 'actions/slot'
import useApi from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { assignSlotPermission } from 'constants/permissions'

const AdminUserNew = () => {
  const [roles, slots] = useApi(() => Promise.all([getAllRoles(), getAllSlots()]))
  const [, , idToken] = useAuth()

  const onSubmit = (data) => {
    createUser(data).then((doc) => {
      navigate(`/admin/users/edit/${doc.id}`)
    })
  }

  const onDiscard = () => {
    navigate('/admin/users')
  }

  return (
    <Form
      buttonText="Create"
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      discardButtonText="Close"
      title="Create User"
      roles={roles?.data}
      slots={slots?.data}
      assignSlot={idToken?.claims?.permissions.includes(assignSlotPermission)}
    />
  )
}
export default AdminUserNew
