import React from 'react'
import Form from '../Form'
import { navigate } from 'gatsby'
import { createReminder } from 'actions/reminder'
import { getAllTemplates } from 'actions/template'
import useApi from 'hooks/useApi'

const NewReminder = () => {
  const [templates] = useApi(() => Promise.all([getAllTemplates()]))

  const onSubmit = (data) =>
    createReminder(data).then((doc) => {
      navigate(`/admin/reminders/edit/${doc.id}`)
    })

  const onDiscard = () => {
    navigate('/admin/reminders')
  }

  return (
    <Form
      buttonText="Create"
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      discardButtonText="Close"
      title="Create Reminder"
      templates={templates?.data}
    />
  )
}

export default NewReminder
