import React, { useState, memo } from 'react'
import { navigate } from 'gatsby'
import DataGrid from 'components/DataGrid'
import columns from './columns'
import { getAllSlots, deleteSlot } from 'actions/slot'
import { SLOT_LABEL } from 'config/app'

const SlotListing = memo(() => {
  const [reload, setReload] = useState(false)

  const onAdd = () => {
    navigate('/admin/slots/new')
  }

  const onEdit = (id) => {
    navigate(`/admin/slots/edit/${id}`)
  }

  const onDelete = (slot) => {
    deleteSlot(slot.id).then(() => {
      setReload(true)
    })
  }

  return (
    <DataGrid
      columns={columns}
      fetchData={getAllSlots}
      onDelete={onDelete}
      onAdd={onAdd}
      onEdit={onEdit}
      title={`${SLOT_LABEL}s`}
      reload={reload}
    />
  )
})

export default SlotListing
export { default as EditSlot } from './Edit'
export { default as NewSlot } from './New'
