import React from 'react'
import { Card, CardBody, CardFooter } from 'components/Card'
import { Typography } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'

export default function Transaction({ transaction }) {
  return (
    <Card>
      <CardBody>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Booking ID
        </Typography>
        <Typography variant="h6" component="h6">
          {transaction.ref.id}
        </Typography>
      </CardBody>
      <CardFooter>
        <Button href={`/admin/bookings/edit/${transaction.ref.id}`}>View Booking</Button>
      </CardFooter>
    </Card>
  )
}
