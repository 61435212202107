import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(theme => ({
  root: {
    margin: 'auto',
    marginTop: '27px',
  },
  label: {
    paddingBottom: '27px',
  },
}))
