import React, { memo } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import Button from 'components/CustomButtons/Button'
import { yupResolver } from '@hookform/resolvers/yup'
import validationSchema from './validation'
import { useForm, Controller } from 'react-hook-form'

export default memo(({ open, onClose, onChangePassword }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  })

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit(onChangePassword)}>
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="currentPassword"
            render={({ field }) => (
              <CustomInput
                {...field}
                type="password"
                label="Current Password"
                error={errors.currentPassword}
                fullWidth
              />
            )}
          />
          <Controller
            control={control}
            name="newPassword"
            render={({ field }) => (
              <CustomInput {...field} type="password" label="New Password" error={errors.newPassword} fullWidth />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field }) => (
              <CustomInput
                {...field}
                type="password"
                label="Confirm Password"
                error={errors.confirmPassword}
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
})
