import React from 'react'
import { GridContainer, GridItem } from 'components/Grid'
import { Controller } from 'react-hook-form'
import TimePicker from 'components/TimePicker'
import CustomCheckbox from 'components/CustomCheckbox'
import CustomInput from 'components/CustomInput'
import { ExposureTwoTone } from '@material-ui/icons'
import { InputAdornment } from '@material-ui/core'

const ApproxTime = ({ classes, hookForm }) => {
  const { control, watch } = hookForm
  const [approxEnabled] = watch(['approx.enabled'])

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <Controller
          control={control}
          name="approx.enabled"
          render={({ field }) => (
            <CustomCheckbox
              {...field}
              InputLabelProps={{
                label: 'Show Approx Time in Confirmation',
                labelPlacement: 'start',
                className: classes.checkbox,
              }}
            />
          )}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Controller
          control={control}
          name="approx.minutes"
          render={({ field }) => (
            <CustomInput
              {...field}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ExposureTwoTone />
                  </InputAdornment>
                ),
              }}
              label="Approx Minutes (+/-)"
              disabled={!approxEnabled}
              fullWidth
            />
          )}
        />
      </GridItem>
    </GridContainer>
  )
}

export default ApproxTime
