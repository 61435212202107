import React, { memo } from 'react'
import Form from '../Form'
import { getTemplate, updateTemplate } from 'actions/template'
import useApi from 'hooks/useApi'

const editTemplate = memo(({ templateId }) => {
  const [template] = useApi(() => Promise.all([getTemplate(templateId)]))

  const onSubmit = (data) => updateTemplate(templateId, template.set(data))

  return (
    <Form buttonText="Update" onSubmit={onSubmit} title="Edit Template" defaultValues={template} dirtyOnStart={false} />
  )
})

export default editTemplate
