import yup from 'utils/yup'
import { statusBooked, statusConfirmed } from 'constants/bookings'

export const defaultValues = {
  slotId: '',
  startDate: '',
  endDate: '',
  status: [statusBooked, statusConfirmed],
}

export const validationSchema = yup.object({
  slotId: yup.string().label('Slot').required(),
  status: yup.array().label('Booking Status').min(1).required(),
  startDate: yup
    .date()
    .label('From Date')
    .transform((v, o) => (o === '' ? undefined : v))
    .required(),
  endDate: yup
    .date()
    .label('To Date')
    .transform((v, o) => (o === '' ? undefined : v)),
})
