import React from 'react'
import Form from 'components/BookingForm'
import { navigate } from 'gatsby'
import useApi from 'hooks/useApi'
import { useAuth } from 'hooks/useAuth'
import { getSlotTimetable } from 'actions/slotTimetable'
import { getSlot } from 'actions/slot'
import { book } from 'actions/booking'
import { updateSlotTimetable } from 'actions/slotTimetable'
import { TYPE_CLOSE, TYPE_OPEN } from 'constants/times'

const NewBooking = ({ slotTimetableId }) => {
  const [slotTimetable, slot] = useApi(() =>
    getSlotTimetable(slotTimetableId).then(async (d) => [d, await getSlot(d.slotId)])
  )
  const [, auth] = useAuth()

  const onSubmit = async (data) =>
    book(slotTimetableId, auth?.uid, data, slot, null, null, true).then((doc) => {
      navigate(`/admin/bookings/edit/${doc.id}`)
    })
  const onBlock = async () =>
    updateSlotTimetable(slotTimetable.id, slotTimetable, {
      type: slotTimetable.type === TYPE_OPEN ? TYPE_CLOSE : TYPE_OPEN,
    }).then(onDiscard)

  const onDiscard = () => {
    navigate('/admin/bookings')
  }

  return (
    <Form
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      onBlock={onBlock}
      title="New Booking"
      slotTimetable={slotTimetable}
      submitButtonText="Create"
      showBlock
      editable
    />
  )
}
export default NewBooking
