import React from 'react'
import ColorPicker from 'components/ColorPicker'
import { GridContainer, GridItem } from 'components/Grid'
import { Controller } from 'react-hook-form'

const colorSelector = ({ control }) => (
  <>
    <GridContainer>
      <GridItem xs={12} sm={12} md={4} lg={2}>
        <Controller
          control={control}
          name="colors.confirmed"
          render={({ field }) => <ColorPicker {...field} label="Confirmed" />}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4} lg={2}>
        <Controller
          control={control}
          name="colors.booked"
          render={({ field }) => <ColorPicker {...field} label="Booked" />}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4} lg={2}>
        <Controller
          control={control}
          name="colors.available"
          render={({ field }) => <ColorPicker {...field} label="Available" />}
        />
      </GridItem>
    </GridContainer>
    <GridContainer>
      <GridItem xs={12} sm={12} md={4} lg={2}>
        <Controller
          control={control}
          name="colors.noShow"
          render={({ field }) => <ColorPicker {...field} label="No Show" />}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4} lg={2}>
        <Controller
          control={control}
          name="colors.noStatus"
          render={({ field }) => <ColorPicker {...field} label="No Status" />}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={4} lg={2}>
        <Controller
          control={control}
          name="colors.notAvailable"
          render={({ field }) => <ColorPicker {...field} label="Not Available" />}
        />
      </GridItem>
    </GridContainer>
  </>
)

export default colorSelector
