import React, { useRef, useEffect } from 'react'
import getStorageDownloadPath from 'actions/base/getStorageDownloadPath'

export default function PreviewImage({ path, ...props }) {
  const ref = useRef()

  useEffect(async () => {
    if (typeof path === 'object') {
      ref.current.src = URL.createObjectURL(path)
    } else {
      ref.current.src = await getStorageDownloadPath(path)
    }
  }, [path])

  return <img ref={ref} {...props} />
}
