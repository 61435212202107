import React from 'react'
import { isSameDay, startOfToday } from 'date-fns'
import Button from 'components/CustomButtons/Button'
import DatePicker from 'components/DatePicker/plain'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import useStyles from './styles'

const Toolbar = ({ minDate, date, onNavigate, label, customButtons: CustomButtons }) => {
  const isMinDate = isSameDay(minDate, date)
  const isToday = isSameDay(startOfToday(), date)
  const classes = useStyles()

  const onChange = (date) => {
    onNavigate(navigate.DATE, date)
  }

  return (
    <div className="rbc-toolbar" style={{ marginTop: 10, padding: '5px 10px' }}>
      <span className="rbc-btn-group">
        <Button variant="text" onClick={() => onNavigate(navigate.TODAY)} disabled={isToday}>
          Today
        </Button>
        <Button variant="text" onClick={() => onNavigate(navigate.PREVIOUS)} disabled={isMinDate}>
          Back
        </Button>
        <Button variant="text" onClick={() => onNavigate(navigate.NEXT)}>
          Next
        </Button>
      </span>
      <span className="rbc-toolbar-label">
        <span>{label}</span>
        <DatePicker value={date} className={classes.input} onChange={onChange} />
      </span>
      {CustomButtons && (
        <span className="rbc-btn-group">
          <CustomButtons date={date} />
        </span>
      )}
    </div>
  )
}

export default Toolbar
