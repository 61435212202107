import React, { memo } from 'react'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { NoSsr } from '@material-ui/core'
// core components
import Navbar from 'components/Navbars/Navbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import { LoadingProvider } from 'hooks/useLoading'
import routes from 'routes/admin'
import { SnackbarProvider } from 'components/Snackbar'
import SEO from 'components/seo'

import styles from 'assets/jss/material-dashboard-react/layouts/adminStyle.js'

const useStyles = makeStyles(styles)

const AdminLayout = memo(({ children, title = 'Home', ...rest }) => {
  // styles
  const classes = useStyles()
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef()
  // states and functions
  const [color] = React.useState('blue')
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <NoSsr>
      <SEO title={title} />
      <SnackbarProvider>
        <LoadingProvider>
          <div className={classes.wrapper}>
            <Sidebar
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color={color}
              bgColor={color}
              routes={routes}
              {...rest}
            />
            <div className={classes.mainPanel} ref={mainPanel}>
              <Navbar handleDrawerToggle={handleDrawerToggle} {...rest} />
              <div className={classes.content}>
                <div className={classes.container}>{children}</div>
              </div>
            </div>
          </div>
        </LoadingProvider>
      </SnackbarProvider>
    </NoSsr>
  )
})
export default AdminLayout
