import React from 'react'
import Form from '../Form'
import { getReminder, updateReminder } from 'actions/reminder'
import { getAllTemplates } from 'actions/template'
import useApi from 'hooks/useApi'

const ReminderEdit = ({ reminderId }) => {
  const [reminder, templates] = useApi(() => Promise.all([getReminder(reminderId), getAllTemplates()]))

  const onSubmit = (data) => updateReminder(reminderId, reminder.set(data))

  return (
    <Form
      buttonText="Update"
      onSubmit={onSubmit}
      title="Edit Reminder"
      defaultValues={reminder}
      dirtyOnStart={false}
      templates={templates?.data}
    />
  )
}

export default ReminderEdit
