import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class TemplateModel extends BaseModel {
  constructor(
    name = '',
    type = '',
    subject = '',
    template = null,
    settings = [],
    reminders = [],
    deletedAt = null,
    id = null
  ) {
    super()
    this.name = name
    this.type = type
    this.subject = subject
    this.template = template
    this.deletedAt = deletedAt
    this.settings = settings
    this.reminders = reminders
    this.id = id
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    type: yup.string().label('Type').required(),
    subject: yup
      .string()
      .label('Subject')
      .when('type', (type, schema) => (type === 'email' ? schema.required() : schema)),
    template: yup.object().nullable().label('Template').required(),
  })
}
export default TemplateModel
