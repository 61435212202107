import React, { forwardRef, memo } from 'react'
import CustomInput from 'components/CustomInput'
import { lightFormat, parse, isValid } from 'date-fns'
import { timeFormat } from 'constants/dateTimeFormat'

export default memo(
  forwardRef(({ InputLabelProps, value, onChange, ...props }, ref) => (
    <CustomInput
      type="time"
      {...props}
      InputLabelProps={{
        shrink: true,
        ...InputLabelProps,
      }}
      value={value && isValid(value) ? lightFormat(value, timeFormat) : value}
      onChange={(e) => onChange(parse(e.target.value, timeFormat, new Date()))}
    />
  ))
)
