import React from 'react'
import Form from '../Form'
import { getSlot, updateSlot } from 'actions/slot'
import { getAllTimes } from 'actions/time'
import { getAllFees } from 'actions/fee'
import { getAllGroups } from 'actions/group'
import useApi from 'hooks/useApi'
import { SLOT_LABEL } from 'config/app'

const AdminSlotEdit = ({ slotId }) => {
  const [slot, times, fees, groups] = useApi(() =>
    Promise.all([getSlot(slotId), getAllTimes(), getAllFees(), getAllGroups()])
  )

  const onSubmit = (data) => updateSlot(slotId, slot.set(data))

  return (
    <Form
      buttonText="Update"
      onSubmit={onSubmit}
      title={`Edit ${SLOT_LABEL}`}
      defaultValues={slot}
      times={times?.data}
      fees={fees?.data}
      groups={groups?.data}
      dirtyOnStart={false}
    />
  )
}
export default AdminSlotEdit
