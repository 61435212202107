import React, { useState, memo } from 'react'
import { navigate } from 'gatsby'
import DataGrid from 'components/DataGrid'
import columns from './columns'
import { getAllFees, deleteFee } from 'actions/fee'
import { FEE_LABEL } from 'config/app'

const FeesListing = memo(() => {
  const [reload, setReload] = useState(false)

  const onAdd = () => {
    navigate('/admin/fees/new')
  }

  const onEdit = (id) => {
    navigate(`/admin/fees/edit/${id}`)
  }

  const onDelete = (fee) => {
    deleteFee(fee.id).then(() => {
      setReload(true)
    })
  }

  const isDeletable = (fee) => fee.slots.length < 1

  return (
    <DataGrid
      columns={columns}
      fetchData={getAllFees}
      onDelete={onDelete}
      onAdd={onAdd}
      onEdit={onEdit}
      title={`${FEE_LABEL}s`}
      reload={reload}
      isDeletable={isDeletable}
    />
  )
})

export default FeesListing
export { default as EditFee } from './Edit'
export { default as NewFee } from './New'
