import React, { useCallback, forwardRef } from 'react'
import { useDropzone } from 'react-dropzone'
import useStyles from './styles.js'
import CustomButton from '../CustomButtons/Button.js'

export default forwardRef(function CustomDropzone({ label, onChange }, ref) {
  const onDrop = useCallback((acceptedFiles) => {
    onChange(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
    },
    onDrop,
  })

  return (
    <div>
      <div ref={ref} {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <CustomButton color="primary">{label ?? 'Click to upload'}</CustomButton>
      </div>
    </div>
  )
})
