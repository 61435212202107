const defaultColor = '#FFFFFF'

const SettingFormDefaults = {
  colors: {
    confirmed: defaultColor,
    noShow: defaultColor,
    noStatus: defaultColor,
    booked: defaultColor,
    available: defaultColor,
    notAvailable: defaultColor,
  },
  hideBookedSlots: false,
  approx: {
    enabled: false,
    minutes: '',
  },
  autoConfirmBookings: false,
  autoConfirmMinutes: '',
  autoCancelBookings: false,
  autoCancelMinutes: '',
  sameDayBooking: true,
  bookingCloseTime: '',
  advancedBookingDays: '',
  editableDays: '',
  welcome: '',
  sms: {
    enabled: false,
    templateId: '',
  },
  email: {
    enabled: false,
    templateId: '',
  },
}

export default SettingFormDefaults
