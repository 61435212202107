import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react'
import 'grapesjs/dist/css/grapes.min.css'
import getFirebaseStorage from 'actions/firebase/storage'
import { checkImageType, initEditor } from './utils'
import { initRte } from './rte'

const mjmlPrefix = '<mjml><mj-body>'
const mjmlSuffix = '</mj-body></mjml>'

const GrapeJsEditor = forwardRef(({ container = 'gjs', mjml = '', html, personalizationTags, ...props }, ref) => {
  const [grapesJsEditor, setGrapesJsEditor] = useState()

  useEffect(() => {
    const grapesJS = require('grapesjs')
    const { default: grapesJSMJML } = require('@linnhtun/grapesjs-mjml')
    const editor = grapesJS.init({
      clearOnRender: true,
      height: '100%',
      fromElement: true,
      plugins: [grapesJSMJML],
      container: `#${container}`,
      assetManager: {
        multiUpload: false,
        upload: 'https://assets',
        embedAsBase64: false,
        uploadFile: (e) => {
          const { files } = e.dataTransfer ? e.dataTransfer : e.target

          for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (checkImageType(file)) {
              const uploadRef = getFirebaseStorage().child(`templates/uploads/${new Date().getTime()}/${file.name}`)
              uploadRef.put(file).then(() => uploadRef.getDownloadURL().then((url) => editor.AssetManager.add(url)))
            }
          }
        },
      },
      storageManager: false,
    })
    initEditor(editor)
    initRte(editor.RichTextEditor, personalizationTags)
    setGrapesJsEditor(editor)
  }, [])

  useImperativeHandle(ref, () => ({
    exportTemplate: () => ({ mjml: grapesJsEditor.getHtml(), ...grapesJsEditor.runCommand('mjml-get-code') }),
  }))

  return <div ref={ref} id={container} dangerouslySetInnerHTML={{ __html: `${mjml}` }} {...props} />
})

export default GrapeJsEditor
