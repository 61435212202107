import React from 'react'
import Form from '../Form'
import { getRole, updateRole } from 'actions/role'
import useApi from 'hooks/useApi'

const AdminRoleEdit = ({ roleId }) => {
  const [role] = useApi(() => Promise.all([getRole(roleId)]))

  const onSubmit = (data) => updateRole(roleId, role.set(data))

  return <Form buttonText="Update" onSubmit={onSubmit} title="Edit Role" defaultValues={role} dirtyOnStart={false} />
}
export default AdminRoleEdit
