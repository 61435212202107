import React, { forwardRef, useRef, useImperativeHandle, useEffect, useState } from 'react'
import CustomInput from 'components/CustomInput'
import { personalizations as suggestions } from 'constants/personalizations'

export default forwardRef(({ value, onChange, ...props }, ref) => {
  const inputRef = useRef(null)
  const [localValue, setLocalValue] = useState(value)
  const localOnChange = (e) => {
    setLocalValue(e.target.value)
  }

  useEffect(() => {
    if (typeof value === 'string') {
      setLocalValue(value)
    }
  }, [value])

  if (!onChange) {
    onChange = localOnChange
  }

  useImperativeHandle(ref, () => ({
    getData: async () => ({ template: localValue, html: localValue }),
  }))

  const onSelect = (e, s) => {
    e.preventDefault()
    const v = inputRef.current.value
    const pos = inputRef.current.selectionStart
    const newValue = `${v.slice(0, pos)}${s.value}${v.slice(pos + 1)}`
    if (onChange !== localOnChange) {
      onChange(newValue)
    } else {
      localOnChange({ target: { value: newValue } })
    }
  }

  return (
    <>
      <CustomInput {...props} value={localValue} inputRef={inputRef} onChange={onChange} />
      {suggestions &&
        suggestions.map((s, key) => (
          <span key={key}>
            {' '}
            <a href="#" onClick={(e) => onSelect(e, s)}>
              {s.label}
            </a>
          </span>
        ))}
    </>
  )
})
