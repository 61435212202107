import React, { memo } from 'react'
import { navigate } from 'gatsby'
import { useAuth } from 'hooks/useAuth'
import Schedules from 'components/Schedules'
import ExportButton from './Export'

const BookingIndex = memo(() => {
  const [loading] = useAuth()

  const onSelectTimetable = (timetable) => {
    if (timetable.booking) {
      navigate(`/admin/bookings/edit/${timetable.booking.id}`)
    } else {
      navigate(`/admin/bookings/new/${timetable.id}`)
    }
  }

  const CustomButtons = (props) => <ExportButton {...props} />

  return (
    <>
      {!loading && <Schedules showBooking={true} onSelectTimetable={onSelectTimetable} customButtons={CustomButtons} />}
    </>
  )
})

export default BookingIndex
export { default as EditBooking } from './Edit'
export { default as NewBooking } from './New'
