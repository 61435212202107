import React, { useState, memo } from 'react'
import { navigate } from 'gatsby'
import DataGrid from 'components/DataGrid'
import columns from './columns'
import { getAllUsers, deleteUser } from 'actions/user'

const UserListing = memo(({ loading }) => {
  const [reload, setReload] = useState(false)

  const onAdd = () => {
    navigate('/admin/users/new')
  }

  const onEdit = (id) => {
    navigate(`/admin/users/edit/${id}`)
  }

  const onDelete = (user) => {
    deleteUser(user.id).then(() => {
      setReload(true)
    })
  }

  return (
    <DataGrid
      columns={columns}
      loading={loading}
      fetchData={getAllUsers}
      onDelete={onDelete}
      onAdd={onAdd}
      onEdit={onEdit}
      title="Users"
      reload={reload}
    />
  )
})

export default UserListing
export { default as EditUser } from './Edit'
export { default as NewUser } from './New'
