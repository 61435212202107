import React, { useEffect, memo } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import CustomAutocomplete from 'components/CustomAutocomplete'
import Form from 'components/Form'
import SlotModel from 'models/Slot'
import defaults from './defaults'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomCheckbox from 'components/CustomCheckbox'
import ArrangeList from 'components/ArrangeList'
import { getTypeLabel } from 'constants/times'
import { FEE_LABEL, GROUP_LABEL } from 'config/app'

export default memo(
  ({
    buttonText,
    discardButtonText,
    title,
    subTitle,
    onSubmit,
    onDiscard,
    defaultValues = defaults,
    dirtyOnStart = true,
    times = [],
    fees = [],
    groups = [],
  }) => {
    const hookForm = useForm({ resolver: yupResolver(SlotModel.validationSchema), defaultValues: { ...defaultValues } })
    const { control, reset } = hookForm

    useEffect(() => {
      reset({ ...defaultValues })
    }, [defaultValues])

    const findSecondary = (value) => {
      return getTypeLabel(value.type)
    }

    return (
      <Form
        title={title}
        subTitle={subTitle}
        dirtyOnStart={dirtyOnStart}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <CustomInput {...field} label="Name" error={error} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState: { error } }) => (
                <CustomInput {...field} label="Description" error={error} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="isPrivate"
              render={({ field, fieldState: { error } }) => (
                <CustomCheckbox
                  {...field}
                  InputLabelProps={{
                    label: 'Is Private?',
                    labelPlacement: 'start',
                    style: {
                      marginTop: 10,
                      marginLeft: 0,
                    },
                  }}
                  error={error}
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="group"
              render={({ field, fieldState: { error } }) => (
                <CustomAutocomplete
                  {...field}
                  label={`${GROUP_LABEL}s`}
                  options={groups}
                  optionValue="id"
                  optionLabel="name"
                  error={error}
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="fees"
              render={({ field, fieldState: { error } }) => (
                <CustomAutocomplete
                  {...field}
                  label={`${FEE_LABEL}s`}
                  options={fees}
                  optionValue="id"
                  optionLabel="name"
                  error={error}
                  fullWidth
                  multiple
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="times"
              render={({ field, fieldState: { error } }) => (
                <ArrangeList
                  {...field}
                  label="Times"
                  error={error}
                  options={times}
                  secondary={findSecondary}
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
      </Form>
    )
  }
)
