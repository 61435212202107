import React, { forwardRef, memo } from 'react'
import { Input } from '@material-ui/core'
import { lightFormat, parseISO, isValid } from 'date-fns'
import { dateFormat } from 'constants/dateTimeFormat'

export default memo(
  forwardRef(({ format = dateFormat, InputLabelProps, value, onChange, ...props }, ref) => (
    <Input
      type="date"
      {...props}
      value={value && isValid(value) ? lightFormat(value, format) : value}
      onChange={(e) => onChange(parseISO(e.target.value))}
      disableUnderline
    />
  ))
)
