import React, { useRef } from 'react'
import Button from 'components/CustomButtons/Button'
import { CloudDownloadTwoTone as CloudDownloadTwoToneIcon } from '@material-ui/icons'
import ExportDialog from './dialog'

const ExportButton = ({ date }) => {
  const dialogRef = useRef()

  const onOpenDialog = () => dialogRef.current.open()
  return (
    <>
      <Button
        variant="text"
        startIcon={<CloudDownloadTwoToneIcon style={{ verticalAlign: 'middle' }} />}
        onClick={onOpenDialog}
      >
        Export Bookings
      </Button>
      <ExportDialog ref={dialogRef} date={date} />
    </>
  )
}

export default ExportButton
