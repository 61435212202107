import React from 'react'
import classNames from 'classnames'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import { MenuItem, MenuList, Grow, Paper, ClickAwayListener, Hidden, Popper, Divider } from '@material-ui/core'
// @material-ui/icons
import { Person } from '@material-ui/icons'
// core components
import Button from 'components/CustomButtons/CustomButton'
import { logout } from 'actions/login'
import { navigate } from 'gatsby'

import styles from 'assets/jss/material-dashboard-react/components/headerLinksStyle.js'
const useStyles = makeStyles(styles)

const AdminNavbarLinks = () => {
  const classes = useStyles()
  const [openProfile, setOpenProfile] = React.useState(null)

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }

  const onLogout = () => {
    logout().then(() => {
      navigate('/login')
    })
  }

  const onProfile = () => {
    navigate('/admin/profile')
  }

  const handleCloseProfile = () => {
    setOpenProfile(null)
  }

  return (
    <ClickAwayListener onClickAway={handleCloseProfile}>
      <div className={classes.manager}>
        <Button
          color={window.innerWidth > 959 ? 'transparent' : 'white'}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? 'profile-menu-list-grow' : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={classNames({ [classes.popperClose]: !openProfile }) + ' ' + classes.popperNav}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <MenuList role="menu">
                  <MenuItem onClick={onProfile} className={classes.dropdownItem}>
                    Profile
                  </MenuItem>
                  <Divider light />
                  <MenuItem onClick={onLogout} className={classes.dropdownItem}>
                    Logout
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export default AdminNavbarLinks
