import { format } from 'date-fns'
import weekdays from 'constants/weekdays'
import permissions from 'constants/permissions'
import { types as reminderTypes } from 'constants/reminders'
import durationTypes from 'constants/durationTypes'
import { format as currencyFormat } from 'utils/currency'
class Converter {
  date(value) {
    return format(value, 'dd/MM/yyyy')
  }
  time(value) {
    return format(value, 'HH:mm')
  }
  boolean(value) {
    return value ? 'Yes' : 'No'
  }
  weekday(value) {
    return Array.isArray(value)
      ? value.map(v => weekdays[v].label).join(', ')
      : weekdays.find(type => type.value === value)?.label
  }
  duration(value) {
    const [number, type] = value
    return `${number} ${durationTypes.find(t => t.value === type)?.label}`
  }
  reminder(value) {
    return Array.isArray(value)
      ? value.map(v => reminderTypes[v].label).join(', ')
      : reminderTypes.find(type => type.value === value)?.label
  }
  permission(value) {
    return Array.isArray(value)
      ? value.map(v => permissions.find(p => p.value === v)?.label).join(', ')
      : permissions.find(p => p.value === value)?.label
  }
  object(value, labelField = 'label') {
    return Array.isArray(value) ? value.map(v => v[labelField]).join(', ') : value[labelField]
  }
  currency(value) {
    return currencyFormat(value)
  }
}

export default ({ type, labelField }, value) => {
  const converter = new Converter()
  type = type ?? typeof value

  if (converter[type] !== undefined) {
    return converter[type](value, labelField)
  }

  return Array.isArray(value) ? value.join(', ') : value
}
