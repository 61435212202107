const columns = [
  {
    field: 'id',
    hide: true,
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'email',
    headerName: 'Email',
  },
  {
    field: 'roles',
    headerName: 'Roles',
    labelField: 'name',
  },
]
export default columns
