import React, { memo } from 'react'
import useStyles from './styles'
import RechartBar from 'components/Chart/RechartBar'
import { bookingStatuses } from 'constants/bookings'

const weeklyStatus = memo(({ data, settings }) => {
  const classes = useStyles()
  return (
    <div className={classes.chartContainer}>
      <RechartBar data={data} keys={bookingStatuses} indexBy="weekday" colors={settings?.colors} />
    </div>
  )
})

export default weeklyStatus
