import React from 'react'
import Form from '../Form'
import { navigate } from 'gatsby'
import { createSlot } from 'actions/slot'
import { getAllTimes } from 'actions/time'
import { getAllFees } from 'actions/fee'
import { getAllGroups } from 'actions/group'
import useApi from 'hooks/useApi'
import { SLOT_LABEL } from 'config/app'

const NewSlot = () => {
  const [times, fees, groups] = useApi(() => Promise.all([getAllTimes(), getAllFees(), getAllGroups()]))

  const onSubmit = (data) =>
    createSlot(data).then((doc) => {
      navigate(`/admin/slots/edit/${doc.id}`)
    })

  const onDiscard = () => {
    navigate('/admin/slots')
  }

  return (
    <Form
      buttonText="Create"
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      discardButtonText="Close"
      title={`Create ${SLOT_LABEL}`}
      times={times?.data}
      groups={groups?.data}
      fees={fees?.data}
    />
  )
}

export default NewSlot
