import BaseModel from 'models/BaseModel'

class PaymentModel extends BaseModel {
  constructor(ref = '', status = '', transactionId = null, confirmedAt = null, id = null) {
    super()
    this.ref = ref
    this.status = status
    this.transactionId = transactionId
    this.confirmedAt = confirmedAt
    this.id = id
  }
}

export default PaymentModel
