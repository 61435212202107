import { BOOKING_EDITABLE } from 'config/app'

export const personalizations = [
  { label: 'Booking ID', value: '{{id}}' },
  { label: 'Time', value: '{{time}}' },
  { label: 'Name', value: '{{name}}' },
  { label: 'Phone', value: '{{phone}}' },
  { label: 'Slot', value: '{{slot}}' },
  { label: 'Booking Confirmation', value: '{{booking_confirmation}}' },
].concat(BOOKING_EDITABLE ? [{ label: 'Booking Link', value: '{{booking_link}}' }] : [])
