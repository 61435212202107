import React from 'react'
import { GridContainer, GridItem } from 'components/Grid'
import { Controller } from 'react-hook-form'
import { BOOKING_EDITABLE } from 'config/app'
import CustomInput from 'components/CustomInput'

const bookingDays = ({ control, errors }) => (
  <GridContainer>
    <GridItem xs={12} sm={12} md={6} lg={4}>
      <Controller
        control={control}
        name="advancedBookingDays"
        render={({ field }) => (
          <CustomInput
            {...field}
            type="number"
            label="Max Advanced Booking Day(s)"
            error={errors.advancedBookingDays}
            inputProps={{ min: 1 }}
            fullWidth
          />
        )}
      />
    </GridItem>
    {BOOKING_EDITABLE && (
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Controller
          control={control}
          name="editableDays"
          render={({ field }) => (
            <CustomInput
              {...field}
              type="number"
              label="Allow To Edit Booking Day(s) Before"
              error={errors.editableDays}
              inputProps={{ min: 1 }}
              fullWidth
            />
          )}
        />
      </GridItem>
    )}
  </GridContainer>
)

export default bookingDays
