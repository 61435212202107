import React, { memo, useRef, useState } from 'react'
import Form from 'components/BookingForm'
import useApi from 'hooks/useApi'
import { getBooking, updateBooking, changeBooking } from 'actions/booking'
import { getSlotTimetable } from 'actions/slotTimetable'
import { getSettings } from 'actions/setting'

const EditBooking = memo(({ bookingId }) => {
  const selectorRef = useRef()
  const [reload, setReload] = useState(0)
  const [booking, slotTimetable, settings] = useApi(
    () =>
      getBooking(bookingId).then(async (booking) => [
        booking,
        await getSlotTimetable(booking.slotTimetableId),
        await getSettings(),
      ]),
    [reload]
  )

  const onSubmit = async (data) => {
    if (booking.slotTimetableId !== data.slotTimetable?.id) {
      return changeBooking(booking.set(data), data.slotTimetable).then(() => {
        setReload(reload + 1)
        selectorRef.current.close()
      })
    }
    return updateBooking(bookingId, booking.set(data))
  }

  if (booking && slotTimetable) {
    booking.slotTimetable = slotTimetable
  }

  return (
    <Form
      selectorRef={selectorRef}
      onSubmit={onSubmit}
      title="Edit Booking"
      submitButtonText="Update"
      dirtyOnStart={false}
      showSubTitle={false}
      slotTimetable={slotTimetable}
      settings={settings}
      defaultValues={booking}
      showStatus
      showSlotSelector
      editable
    />
  )
})

export default EditBooking
