import React from 'react'
import Form from '../Form'
import { getGroup, updateGroup } from 'actions/group'
import { getAllTimes } from 'actions/time'
import { getAllFees } from 'actions/fee'
import useApi from 'hooks/useApi'
import { SLOT_LABEL, FEE_LABEL, GROUP_LABEL } from 'config/app'

const AdminGroupEdit = ({ groupId }) => {
  const [group, times, fees] = useApi(() => Promise.all([getGroup(groupId), getAllTimes(), getAllFees()]))

  const onSubmit = (data) => updateGroup(groupId, group.set(data))

  return (
    <Form
      buttonText="Update"
      onSubmit={onSubmit}
      title={`Edit ${GROUP_LABEL}`}
      defaultValues={group}
      times={times?.data}
      fees={fees?.data}
      dirtyOnStart={false}
    />
  )
}
export default AdminGroupEdit
