import getFirestorage from 'actions/firebase/storage'
import { ref, getDownloadURL } from 'firebase/storage'
import { format } from 'date-fns'
import axios from 'axios'
import weekdays from 'constants/weekdays'
import { bookingStatuses } from 'constants/bookings'

const getFile = async () => {
  const pathRef = ref(getFirestorage(), `dashboard/${format(new Date(), 'yyyyww')}.json`)

  try {
    const url = await getDownloadURL(pathRef)
    const { data } = await axios.get(url)

    return weekdays.map(({ label, value }) => {
      const d = {}
      if (data[value]) {
        Object.keys(data[value]).map((k) => (d[bookingStatuses.find((s) => k === s.value)?.label] = data[value][k]))
      }
      return { weekday: label, ...d }
    })
  } catch (e) {}

  return weekdays.map(({ label }) => ({ weekday: label }))
}

export default getFile
