import React, { useEffect, memo } from 'react'
import Form from '../Form'
import { navigate } from 'gatsby'
import { createTemplate } from 'actions/template'
import { useLoading } from 'hooks/useLoading'

const NewTemplate = memo(() => {
  const [, setLoading] = useLoading()

  useEffect(() => {
    setLoading(false)
  }, [])

  const onSubmit = (data) => {
    createTemplate(data).then((doc) => {
      navigate(`/admin/templates/edit/${doc.id}`)
    })
  }

  const onDiscard = () => {
    navigate('/admin/templates')
  }

  return (
    <Form
      buttonText="Create"
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      discardButtonText="Close"
      title="Create Template"
    />
  )
})

export default NewTemplate
