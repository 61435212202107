import React, { useEffect } from 'react'
import Form from '../Form'
import { navigate } from 'gatsby'
import { createRole } from 'actions/role'
import { useLoading } from 'hooks/useLoading'

const AdminRoleNew = () => {
  const [, setLoading] = useLoading()

  useEffect(() => {
    setLoading(false)
  }, [])

  const onSubmit = (data) => {
    createRole(data).then((doc) => {
      navigate(`/admin/roles/edit/${doc.id}`)
    })
  }

  const onDiscard = () => {
    navigate('/admin/roles')
  }

  return (
    <Form buttonText="Create" onSubmit={onSubmit} onDiscard={onDiscard} discardButtonText="Close" title="Create Role" />
  )
}
export default AdminRoleNew
