import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class UserModel extends BaseModel {
  constructor(name = '', email = '', description = '', roles = [], slots = [], deletedAt = null, id = null) {
    super()
    this.name = name
    this.email = email
    this.description = description
    this.roles = roles
    this.slots = slots
    this.deletedAt = deletedAt
    this.id = id
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    email: yup.string().email().label('Email').required(),
    roles: yup.array().label('Roles').required(),
    slots: yup.array().label('Slots').required(),
  })

  static profileValidationSchema = yup.object({
    name: yup.string().label('Name').required(),
    email: yup.string().email().label('Email').required(),
  })
}
export default UserModel
