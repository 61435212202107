import React, { memo } from 'react'
import useApi from 'hooks/useApi'
import Gateway from './gateway'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { paymentGateways } from 'config/app'
import { getAllPaymentGateways } from 'actions/paymentGateway'
import useStyles from './styles'

const PaymentsListing = memo((props) => {
  const [gateways] = useApi(() => Promise.all([getAllPaymentGateways()]))
  const classes = useStyles()

  return (
    <GridContainer>
      {paymentGateways.map((pg) => (
        <GridItem key={pg} xs={2} sm={3} md={3}>
          <Gateway gateway={pg} config={gateways?.data.find((g) => g?.id === pg)} classes={classes} {...props} />
        </GridItem>
      ))}
    </GridContainer>
  )
})

export default PaymentsListing
export { default as EditPayment } from './Edit'
