import React, { useEffect, memo, useState } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import CustomAutocomplete from 'components/CustomAutocomplete'
import Form from 'components/Form'
import ReminderModel from 'models/Reminder'
import defaults from './defaults'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { types } from 'constants/reminders'
import durationTypes from 'constants/durationTypes'
import { bookingStatuses } from 'constants/bookings'

export default memo(
  ({
    buttonText,
    discardButtonText,
    title,
    subTitle,
    onSubmit,
    onDiscard,
    templates,
    defaultValues = defaults,
    dirtyOnStart = true,
  }) => {
    const hookForm = useForm({
      resolver: yupResolver(ReminderModel.validationSchema),
      defaultValues: { ...defaultValues },
    })
    const [typedTemplates, setTypedTemplates] = useState([])
    const {
      control,
      formState: { errors },
      watch,
      setValue,
      getValues,
      reset,
    } = hookForm
    const watchReminderType = watch('type')

    useEffect(() => {
      if (watchReminderType) {
        const filteredTemplates = templates.filter((t) => t.type === watchReminderType)
        setTypedTemplates(filteredTemplates)

        const selectedTemplateId = getValues('templateId')
        if (!filteredTemplates.find((t) => t.id === selectedTemplateId)) {
          setValue('templateId', '')
        }
      }
    }, [watchReminderType])

    useEffect(() => {
      reset({ ...defaultValues })
    }, [defaultValues])

    return (
      <Form
        title={title}
        subTitle={subTitle}
        dirtyOnStart={dirtyOnStart}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        defaultValues={defaultValues}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <CustomInput {...field} label="Name" error={errors.name} fullWidth required />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <CustomInput {...field} label="Description" error={errors.description} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <CustomSelect {...field} label="Type" error={errors.type} options={types} fullWidth required />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="bookingStatus"
              render={({ field }) => (
                <CustomAutocomplete
                  {...field}
                  label="Booking Status"
                  error={errors.bookingStatus}
                  options={bookingStatuses}
                  fullWidth
                  multiple
                  required
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="durationNumber"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  label="Number of Day(s) or Hour(s) before"
                  error={errors.durationNumber}
                  fullWidth
                  required
                />
              )}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="durationType"
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  label="Day(s) or Hour(s)"
                  error={errors.durationType}
                  options={durationTypes}
                  fullWidth
                  required
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="templateId"
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  label="Choose Template"
                  error={errors.templateId}
                  options={typedTemplates}
                  optionLabel="name"
                  optionValue="id"
                  fullWidth
                  required
                />
              )}
            />
          </GridItem>
        </GridContainer>
      </Form>
    )
  }
)
