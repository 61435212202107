import React from 'react'
import { GridContainer, GridItem } from 'components/Grid'
import { Controller } from 'react-hook-form'
import TimePicker from 'components/TimePicker'
import CustomCheckbox from 'components/CustomCheckbox'

const SameDayBookings = ({ classes, hookForm }) => {
  const { control, watch } = hookForm
  const [allowedSameDayBooking] = watch(['sameDayBooking'])

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={4}>
        <Controller
          control={control}
          name="sameDayBooking"
          render={({ field }) => (
            <CustomCheckbox
              {...field}
              InputLabelProps={{
                label: 'Allow Same Day Booking',
                labelPlacement: 'start',
                className: classes.checkbox,
              }}
            />
          )}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={6} lg={4}>
        <Controller
          control={control}
          name="bookingCloseTime"
          render={({ field }) => (
            <TimePicker {...field} label="Booking Close Time For The Day" disabled={allowedSameDayBooking} fullWidth />
          )}
        />
      </GridItem>
    </GridContainer>
  )
}

export default SameDayBookings
