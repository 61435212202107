import { useState, useEffect } from 'react'
import { useLoading } from './useLoading'
import useDebounce from './useDebounce'

export default (fetchData, reload) => {
  const [pageInfo, setPageInfo] = useState({
    action: 0,
    page: 0,
    limit: 10,
  })
  const [data, setData] = useState({ data: [], lastItem: null })
  const [keyword, setKeyword] = useState('')
  const [filter, setFilter] = useState('')
  const [, setLoading] = useLoading()

  useDebounce(
    () => {
      if (keyword) {
        setFilter(keyword)
      }
    },
    500,
    [keyword]
  )

  useEffect(() => {
    fetchData({
      keyword,
      filter,
      pageInfo: { firstItem: data.data[0], lastItem: data.data[data.data.length - 1], ...pageInfo },
    }).then((data) => {
      setData(data)
      setLoading(false)
    })
  }, [pageInfo, reload, filter])

  return { ...data, pageInfo, setKeyword, setPageInfo }
}
