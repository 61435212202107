import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'

class ReminderModel extends BaseModel {
  constructor(
    name = '',
    description = '',
    type = '',
    bookingStatus = [],
    durationNumber = '',
    durationType = '',
    templateId = '',
    deletedAt = null,
    id = null
  ) {
    super()
    this.name = name
    this.description = description
    this.type = type
    this.bookingStatus = bookingStatus
    this.durationNumber = durationNumber
    this.durationType = durationType
    this.templateId = templateId
    this.deletedAt = deletedAt
    this.id = id
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
    type: yup.string().label('Type').required(),
    bookingStatus: yup.array().min(1, 'Booking Status is a required field').label('Booking Status').required(),
    durationNumber: yup
      .number()
      .label('Number of Day(s) or Hour(s)')
      .required()
      .positive()
      .integer()
      .transform((v, o) => (o === '' ? undefined : v)),
    durationType: yup.string().label('Day(s) or Hour(s)').required(),
    templateId: yup.string().label('Template').required(),
  })
}
export default ReminderModel
