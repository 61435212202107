import React, { useEffect, memo } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import CustomDropzone from 'components/CustomDropzone'
import Form from 'components/Form'
import GroupModel from 'models/Group'
import defaults from './defaults'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomCheckbox from 'components/CustomCheckbox'
import { getTypeLabel } from 'constants/times'
import PreviewImage from 'components/PreviewImage'

export default memo(
  ({
    buttonText,
    discardButtonText,
    title,
    subTitle,
    onSubmit,
    onDiscard,
    defaultValues = defaults,
    dirtyOnStart = true,
  }) => {
    const hookForm = useForm({
      resolver: yupResolver(GroupModel.validationSchema),
      defaultValues: { ...defaultValues },
    })
    const {
      control,
      formState: { errors },
      reset,
    } = hookForm

    useEffect(() => {
      reset({ ...defaultValues })
    }, [defaultValues])

    const findSecondary = (value) => {
      return getTypeLabel(value.type)
    }

    return (
      <Form
        title={title}
        subTitle={subTitle}
        dirtyOnStart={dirtyOnStart}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="photo"
              render={({ field, fieldState: { error } }) => (
                <>
                  {field.value && <PreviewImage path={field.value} height={150} />}
                  <CustomDropzone {...field} label="Upload Photo" error={error} fullWidth />
                </>
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field, fieldState: { error } }) => (
                <CustomInput {...field} label="Name" error={error} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="description"
              render={({ field, fieldState: { error } }) => (
                <CustomInput {...field} label="Description" error={error} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="isPrivate"
              render={({ field, fieldState: { error } }) => (
                <CustomCheckbox
                  {...field}
                  InputLabelProps={{
                    label: 'Is Private?',
                    labelPlacement: 'start',
                    style: {
                      marginTop: 10,
                      marginLeft: 0,
                    },
                  }}
                  error={error}
                />
              )}
            />
          </GridItem>
        </GridContainer>
      </Form>
    )
  }
)
