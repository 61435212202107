import React from 'react'
import { Router } from '@gatsbyjs/reach-router'
import Layout from 'components/Layout/Admin'
import PrivateRoute from 'components/PrivateRoute'
import Profile from 'components/Admin/Profile'
import routes from 'routes/admin'
import { AuthProvider } from 'hooks/useAuth'

const AdminPage = () => {
  const LayoutedComponent = (Component, title) => (props) => (
    <Layout title={title} {...props}>
      <Component {...props} />
    </Layout>
  )

  return (
    <AuthProvider>
      <Router>
        {routes.flatMap(({ name, component, path, routes, ...props }) => {
          let com = [
            <PrivateRoute key={name} path={path.substr(1)} component={LayoutedComponent(component, name)} {...props} />,
          ]
          if (routes) {
            com = com.concat(
              routes.map(({ name, component, routes, ...props }, key) => (
                <PrivateRoute
                  key={key}
                  path={path.substr(1)}
                  component={LayoutedComponent(component, name)}
                  {...props}
                />
              ))
            )
          }
          return com
        })}
        <PrivateRoute path="admin/profile" component={LayoutedComponent(Profile, 'Profile')} />
      </Router>
    </AuthProvider>
  )
}

export default AdminPage
