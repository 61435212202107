import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import GrapeJsEditor from './GrapeJsEditor'
import { personalizations } from 'constants/personalizations'

export default forwardRef(({template: mjml, ...props}, ref) => {
  const emailEditorRef = useRef(null)

  useImperativeHandle(ref, () => ({
    getData: async () => {
      const { mjml, html } = await emailEditorRef.current.exportTemplate()
      return { html, template: mjml }
    },
  }))

  return <GrapeJsEditor ref={emailEditorRef} mjml={mjml} {...props} personalizationTags={personalizations} />
})
